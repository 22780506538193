import React, {useEffect} from 'react';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {HomeStackParamList, IUser} from '../types';
import ChatScreen from './chat/ChatScreen';
import FeedbackModalScreen from './feedback/FeedbackModalScreen';
import {useTheme} from '@react-navigation/native';
import SignUpModalScreen from './auth/SignUpModalScreen';
import {auth} from '../services/firebase';
import {useAppDispatch, useAppSelector} from '../redux/store';
import {createUser, getUserById} from '../services/userService';
import {createThread} from '../services/functions';
import {logOutAction, setUserAction} from '../redux/slices/authSlice';
import {signInAnonymously} from 'firebase/auth';
import {Spinner} from '../components';
import AnalyticsScreen from './AnalyticsScreen';
import {Timestamp} from 'firebase/firestore';

async function getThreadId(uid: string): Promise<IUser> {
  const user = await getUserById(uid);
  if (user) {
    return user;
  } else {
    const threadId = await createThread();
    await createUser({
      _id: uid,
      threadId,
    });
    return {
      _id: uid,
      threadId,
      createdAt: Timestamp.now(),
    };
  }
}

const Stack = createNativeStackNavigator<HomeStackParamList>();

export default function Root() {
  const dispatch = useAppDispatch();
  const {colors: themeColors} = useTheme();

  const user = useAppSelector(state => state.auth.user);

  useEffect(() => {
    return auth.onAuthStateChanged(
      async u => {
        try {
          // setIsLoading(true);
          if (u) {
            const _user = await getThreadId(u.uid);
            dispatch(setUserAction({..._user, isAnonymous: u.isAnonymous}));
            // setIsLoading(false);
          } else {
            dispatch(logOutAction());
            await signInAnonymously(auth);
          }
        } catch (e) {
          // setIsLoading(false);
          dispatch(logOutAction());
          console.error(e);
          alert((e as Error).message);
        }
      },
      e => {
        console.error(e);
        alert(e.message);
      },
    );
  }, [dispatch]);

  if (user) {
    return (
      <Stack.Navigator
        screenOptions={{
          headerTintColor: themeColors.primary,
          headerTitleStyle: {color: themeColors.text, fontWeight: '700'},
          headerTitleAlign: 'center',
        }}>
        <Stack.Screen
          name={'ChatScreen'}
          component={ChatScreen}
          options={{
            headerShown: false,
            title: 'Ask Don Juan',
            // headerTitle: () => <Title />,
          }}
        />
        <Stack.Screen
          name={'FeedbackModalScreen'}
          component={FeedbackModalScreen}
          options={{title: 'Feedback', presentation: 'transparentModal', headerShown: false}}
        />
        <Stack.Screen
          name={'SignUpModalScreen'}
          component={SignUpModalScreen}
          options={{title: 'Feedback', presentation: 'transparentModal', headerShown: false}}
        />
        <Stack.Screen name="AnalyticsScreen" component={AnalyticsScreen} />
      </Stack.Navigator>
    );
  }
  return (
    <Stack.Navigator
      screenOptions={{
        headerTintColor: themeColors.primary,
        headerTitleStyle: {color: themeColors.text, fontWeight: '700'},
        headerTitleAlign: 'center',
        title: 'Ask Don Juan',
      }}>
      <Stack.Screen name={'Spinner'} component={Spinner} />
    </Stack.Navigator>
  );
}
