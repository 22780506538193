import React from 'react';
import {Bubble as GCBubble, BubbleProps} from 'react-native-gifted-chat';
import {Image, StyleSheet, View} from 'react-native';
import {colors} from '../../../constants';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import MaterialCommunityIcons from '@expo/vector-icons/MaterialCommunityIcons';
import {IMessage} from '../../../types';

const typing = require('../../../assets/typing_loading.gif');

const styles = StyleSheet.create({
  typingLoadingContainer: {
    paddingHorizontal: 14,
    paddingVertical: 7,
  },
  typingLoading: {
    width: 40,
    height: 20,
  },
  redColor: {color: colors.red},
  rightMargin6: {marginRight: 6},
});

function Bubble(_props: BubbleProps<IMessage>) {
  return (
    <GCBubble
      {..._props}
      wrapperStyle={{right: {backgroundColor: colors.primary}}}
      textStyle={{left: [_props.currentMessage?.failed && styles.redColor]}}
      renderCustomView={
        _props.currentMessage?.text === '' && _props.position === 'left'
          ? () => {
              return (
                <View style={styles.typingLoadingContainer}>
                  <Image source={typing} style={styles.typingLoading} />
                </View>
              );
            }
          : undefined
      }
      renderTicks={() => {
        if (_props.currentMessage?.failed) {
          return (
            <MaterialIcons name={'error'} size={15} color={'red'} style={styles.rightMargin6} />
          );
        }
        if (_props.position === 'right' && _props.currentMessage?.pending) {
          return (
            <MaterialIcons
              name={'access-time'}
              size={14}
              color={colors.card}
              style={styles.rightMargin6}
            />
          );
        }
        if (_props.position === 'right' && _props.nextMessage?.user?._id === 'assistant') {
          return (
            <MaterialCommunityIcons
              name={'check-all'}
              size={14}
              color={colors.card}
              style={styles.rightMargin6}
            />
          );
        }
      }}
    />
  );
}

function areEqual(prevProps: BubbleProps<IMessage>, nextProps: BubbleProps<IMessage>) {
  const prevText = prevProps.currentMessage?.text || '';
  const nextText = nextProps.currentMessage?.text || '';

  // Only update if the new text is longer than the previous text
  const longer = nextText.length <= prevText.length;
  if (!longer) {
    // console.log('updating ====>', nextProps.currentMessage?.text.substring(10));
  }
  return longer;
}

export default React.memo(Bubble, areEqual);
