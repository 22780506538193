import React, {useCallback, useEffect, useState} from 'react';
import {
  Button,
  Flex,
  IconButton,
  ModalContainer,
  Pressable,
  Sizes,
  Text,
  Weights,
} from '../../components';
import {StyleSheet, View} from 'react-native';
import {useTheme} from '@react-navigation/native';
import FontAwesome from '@expo/vector-icons/FontAwesome';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {HomeStackParamList} from '../../types';
import SignUpWithEmailForm from './components/SignUpWithEmailForm';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import SignInWithEmailForm from './components/SignInWithEmailForm';
import {auth} from '../../services/firebase';
import {
  GoogleAuthProvider,
  linkWithPopup,
  AuthError,
  AuthErrorCodes,
  signInWithCredential,
} from 'firebase/auth';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {updateUserAction} from '../../redux/slices/authSlice';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
  },
  modal: {
    maxWidth: 600,
    flexGrow: 1,
    padding: 20,
    paddingBottom: 40,
    borderRadius: 20,
  },
  height1: {height: 1},
  padding10: {padding: 10},
});

const provider = new GoogleAuthProvider();

type Props = NativeStackScreenProps<HomeStackParamList, 'SignUpModalScreen'>;

export default function SignUpModalScreen(props: Props) {
  const {
    navigation,
    route: {
      params: {forced},
    },
  } = props;

  const dispatch = useAppDispatch();

  const {colors: themeColors} = useTheme();
  const user = useAppSelector(state => state.auth.user);
  const [isLoading, setIsLoading] = useState(false);
  const [hasAccount, setHasAccount] = useState(false);

  useEffect(() => {
    if (!user.isAnonymous) {
      navigation.goBack();
    }
  }, [navigation, user.isAnonymous]);

  const onPressGoogle = useCallback(async () => {
    try {
      setIsLoading(true);
      await linkWithPopup(auth.currentUser!, provider);
      await auth.currentUser!.reload();
      dispatch(updateUserAction({isAnonymous: auth.currentUser!.isAnonymous}));
      navigation.goBack();
    } catch (e) {
      const credential = GoogleAuthProvider.credentialFromError(e as AuthError);
      if ((e as AuthError).code === AuthErrorCodes.CREDENTIAL_ALREADY_IN_USE && credential) {
        await signInWithCredential(auth, credential).finally(() => {
          setIsLoading(false);
        });
      } else {
        setIsLoading(false);
        console.error(e);
        alert((e as Error).message);
      }
    }
  }, [navigation, dispatch]);

  return (
    <ModalContainer style={styles.container}>
      <Flex gap={40} style={[styles.modal, {backgroundColor: themeColors.card}]}>
        <Flex alignItems={'flex-end'}>
          {!forced && (
            <IconButton onPress={navigation.goBack}>
              <MaterialIcons name={'close'} size={24} color={themeColors.text} />
            </IconButton>
          )}

          <Flex pointerEvents={'none'} style={StyleSheet.absoluteFill} alignItems={'center'}>
            <Text size={Sizes.Title1} weight={Weights.Bold}>
              {hasAccount ? 'Log in' : 'Sign up'}
            </Text>
          </Flex>
        </Flex>

        <Flex gap={10}>
          {hasAccount ? (
            <SignInWithEmailForm
              onSuccess={navigation.goBack}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          ) : (
            <SignUpWithEmailForm
              onSuccess={navigation.goBack}
              setIsLoading={setIsLoading}
              isLoading={isLoading}
            />
          )}

          <Flex direction={'row'} justifyContent={'center'}>
            <Text>{hasAccount ? 'Do not have an account?' : 'Already have an account?'}</Text>
            <Pressable
              style={styles.padding10}
              onPress={() => setHasAccount(prevState => !prevState)}>
              <Text weight={Weights.Bold}>{hasAccount ? 'Sign up' : 'Log in'}</Text>
            </Pressable>
          </Flex>
        </Flex>

        <View style={[styles.height1, {backgroundColor: themeColors.border}]} />

        <Flex alignItems={'center'}>
          <Button loading={isLoading} onPress={onPressGoogle}>
            <Flex gap={12} direction={'row'}>
              <FontAwesome name={'google'} size={24} color={themeColors.card} />
              <Text color={themeColors.card} weight={Weights.Medium}>
                Continue with Google
              </Text>
            </Flex>
          </Button>
        </Flex>
      </Flex>
    </ModalContainer>
  );
}
