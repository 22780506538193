import {IMessage} from '../../types';

export const initialMessages = [
  {
    _id: '1',
    failed: false,
    text: 'Hello! How can I assist you today?',
    createdAt: new Date(),
    updatedAt: new Date(),
    // threadId,
    user: {
      _id: 'initial',
      name: 'assistant',
    },
    quickReplies: {
      type: 'radio', // or 'checkbox',
      values: [
        {
          title: 'How do I approach a girl?',
          value: 'How do I approach a girl?',
        },
        {
          title: 'How do I go in for the kiss?',
          value: 'How do I go in for the kiss?',
        },
        {
          title: 'What should be my opening line on Tinder? ',
          value: 'What should be my opening line on Tinder? ',
        },
        {
          title: 'How do I get a girls phone number?',
          value: 'How do I get a girls phone number?',
        },
      ],
    },
  },
] as IMessage[];
