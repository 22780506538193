import React from 'react';
import {PressableProps, StyleSheet} from 'react-native';
import Pressable from './Pressable';
import {useTheme} from '@react-navigation/native';

const styles = StyleSheet.create({
  button: {
    height: 49,
    width: 49,
    borderRadius: 49,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

type Props = PressableProps & {color?: string; hoverColor?: string};

export default function IconButton(props: Props) {
  const {style, color, hoverColor, ...buttonProps} = props;

  const {colors: themeColors} = useTheme();

  return (
    <Pressable
      style={state => [
        styles.button,
        {backgroundColor: color || themeColors.card},
        typeof style === 'function' ? style(state) : style,
      ]}
      hoverColor={hoverColor || themeColors.background}
      {...buttonProps}
    />
  );
}
