import {Collections, OnCreateFeedback} from '../types';
import {collection, doc, serverTimestamp, setDoc} from 'firebase/firestore';
import {firestore} from './firebase';

const COLLECTION = Collections.FEEDBACKS;

export function createFeedback(feedback: OnCreateFeedback) {
  const ref = doc(collection(firestore, COLLECTION));
  return setDoc(ref, {
    ...feedback,
    id: ref.id,
    isDeleted: false,
    createdAt: serverTimestamp(),
    updatedAt: serverTimestamp(),
  });
}
