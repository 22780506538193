import {Image, StyleSheet, View} from 'react-native';
import {Text} from '../../components';
import React from 'react';

const styles = StyleSheet.create({
  emptyContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    padding: 20,
  },
  emptyText: {
    fontSize: 18,
    color: 'gray',
    textAlign: 'center',
    transform: [{rotateX: '180deg'}],
  },
  image: {
    width: 150,
    height: 150,
    marginBottom: 20,
    borderRadius: 75,
    transform: [{rotateX: '180deg'}],
  },
});

export const EmptyChatComponent = () => (
  <View style={styles.emptyContainer}>
    <Text style={styles.emptyText}>
      Ask Don Juan is your personal companion for all things dating. From approaching the girl in
      person to getting her in your bed, Ask Don Juan is here to help. Ask Don Juan can also help
      you with online dating. It can improve your profile and give you all the right messages.
    </Text>
    <Image
      source={require('../../../assets/icon.png')} // Update the path to your local image
      style={styles.image}
    />
  </View>
);
