import {Collections, IMessage, MessageDoc} from '../types';
import {
  collection,
  onSnapshot,
  orderBy,
  query,
  where,
  updateDoc,
  doc,
  addDoc,
  serverTimestamp,
} from 'firebase/firestore';
import {firestore} from './firebase';

function serializeMessage(messageDoc: MessageDoc): IMessage {
  // console.log(messageDoc.values?.sort().reverse()[0]);
  return {
    ...messageDoc,
    text: messageDoc.text,
    createdAt: messageDoc.createdAt ? messageDoc.createdAt.toDate() : new Date(),
    updatedAt: messageDoc.updatedAt ? messageDoc.updatedAt.toDate() : new Date(),
  };
}

const COLLECTION = Collections.MESSAGES;

export function updateMessage(_id: string, updatedFields: Partial<Omit<IMessage, '_id'>>) {
  return updateDoc(doc(firestore, COLLECTION, _id.toString()), {
    ...updatedFields,
    updatedAt: serverTimestamp(),
  });
}

export function listenMessages(threadId: string, setMessages: (message: IMessage[]) => void) {
  return onSnapshot(
    query(
      collection(firestore, COLLECTION),
      where('threadId', '==', threadId),
      orderBy('createdAt', 'desc'),
    ),
    snapshot => {
      setMessages(snapshot.docs.map(_doc => serializeMessage(_doc.data() as MessageDoc)));
    },
    e => {
      console.error(e.message);
      alert(e.message);
    },
  );
}

export async function addMessage(message: IMessage & {threadId: string}): Promise<void> {
  try {
    await addDoc(collection(firestore, COLLECTION), {
      ...message,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });
    console.log('Message successfully added');
  } catch (error) {
    console.error('Error adding message:', error);
    // @ts-ignore
    alert('Error adding message: ' + error.message);
  }
}
