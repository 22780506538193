import React from 'react';
import {StyleSheet, View, ViewProps, ViewStyle} from 'react-native';

export type FlexProps = ViewProps &
  Pick<ViewStyle, 'gap' | 'alignItems' | 'justifyContent'> & {
    direction?: ViewStyle['flexDirection'];
  };

export default function Flex(props: FlexProps) {
  const {
    style: _style,
    gap,
    direction = 'column',
    alignItems,
    justifyContent,
    ...viewProps
  } = props;

  const style = StyleSheet.flatten([
    {
      gap,
      flexDirection: direction,
      alignItems: alignItems || (direction === 'row' ? 'center' : undefined),
      justifyContent,
    },
    _style,
  ]);

  return <View style={style} {...viewProps} />;
}
