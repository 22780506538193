import React from 'react';
import {StyleSheet} from 'react-native';
import FeedbackForm from './components/FeedbackForm';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {HomeStackParamList} from '../../types';
import {Flex, IconButton, ModalContainer, Sizes, Text, Weights} from '../../components';
import MaterialIcons from '@expo/vector-icons/MaterialIcons';
import {useTheme} from '@react-navigation/native';

const styles = StyleSheet.create({
  container: {flexDirection: 'row'},
  modal: {
    borderRadius: 20,
    backgroundColor: '#fff',
    maxWidth: 600,
    flexGrow: 1,
    elevation: 30,
    paddingTop: 20,
  },
  modalHeader: {
    paddingHorizontal: 16,
  },
  modalBody: {
    paddingHorizontal: 20,
    paddingBottom: 25,
  },
});

type Props = NativeStackScreenProps<HomeStackParamList, 'FeedbackModalScreen'>;

export default function FeedbackModalScreen(props: Props) {
  const {navigation} = props;

  const {colors: themeColors} = useTheme();

  return (
    <ModalContainer style={styles.container}>
      <Flex gap={10} style={styles.modal}>
        <Flex alignItems={'flex-end'} style={styles.modalHeader}>
          <IconButton onPress={navigation.goBack}>
            <MaterialIcons name={'close'} size={24} color={themeColors.text} />
          </IconButton>
          <Flex
            pointerEvents={'none'}
            style={StyleSheet.absoluteFill}
            alignItems={'center'}
            justifyContent={'center'}>
            <Text weight={Weights.Bold} size={Sizes.Title1}>
              Feedback Form
            </Text>
          </Flex>
        </Flex>
        <Flex style={styles.modalBody}>
          <FeedbackForm />
        </Flex>
      </Flex>
    </ModalContainer>
  );
}
