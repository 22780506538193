import React from 'react';
import {ImageBackground, StyleSheet, ViewProps} from 'react-native';

const background = require('../assets/rectangle.png');

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default function ModalContainer(props: Pick<ViewProps, 'children' | 'style'>) {
  const {children, style} = props;

  return (
    <ImageBackground source={background} style={[styles.container, style]}>
      {children}
    </ImageBackground>
  );
}
