import {Collections, IUser, OnCreateUser} from '../types';
import {doc, getDoc, serverTimestamp, setDoc} from 'firebase/firestore';
import {firestore} from './firebase';

const COLLECTION = Collections.USERS;

export async function createUser(user: OnCreateUser): Promise<void> {
  try {
    await setDoc(doc(firestore, COLLECTION, user._id), {
      ...user,
      createdAt: serverTimestamp(),
    });
    console.log('User successfully created');
  } catch (error) {
    console.error('Error creating user:', error);
  }
}

export async function getUserById(id: string): Promise<IUser | null> {
  try {
    const userDoc = await getDoc(doc(firestore, COLLECTION, id));
    if (userDoc.exists()) {
      return userDoc.data() as IUser;
    } else {
      console.error('No such document!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching user:', error);
    return null;
  }
}
