import React from 'react';
import Root from './src/screens/Root';
import {NavigationContainer} from '@react-navigation/native';
import {LightTheme} from './src/constants';
import {Provider} from 'react-redux';
import store, {persistor} from './src/redux/store';
import {PersistGate} from 'redux-persist/integration/react';

export default function App() {
  return (
    <NavigationContainer theme={LightTheme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Root />
        </PersistGate>
      </Provider>
    </NavigationContainer>
  );
}
