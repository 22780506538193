import React, {PropsWithChildren, useMemo} from 'react';
import {ActivityIndicator, PressableProps, StyleSheet, TextProps} from 'react-native';
import Text from '../text/Text';
import {useTheme} from '@react-navigation/native';
import Flex from '../Flex';
import {colors} from '../../constants';
import Pressable from './Pressable';

const styles = StyleSheet.create({
  button: {
    height: 57,
    minWidth: 300,
    borderRadius: 16,
    alignItems: 'center',
    justifyContent: 'center',
    paddingHorizontal: 20,
    borderWidth: 1,
  },
  loadingContainer: {
    position: 'absolute',
    top: -1,
    bottom: -1,
    left: -1,
    right: -1,
    borderRadius: 16,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    backgroundColor: 'rgba(255,255,255,0.7)',
  },
  loading: {
    height: '100%',
    width: 52,
  },
});

type ButtonType = 'primary' | 'secondary';

type Props = Omit<PressableProps, 'children'> &
  PropsWithChildren<{
    color?: string;
    hoverColor?: string;
    title?: string;
    titleStyle?: TextProps['style'];
    loading?: boolean;
    type?: ButtonType;
  }>;

export default function Button(props: Props) {
  const {
    type = 'primary',
    loading = false,
    disabled = false,
    style,
    titleStyle,
    color,
    hoverColor: _hoverColor,
    title,
    children,
    ...buttonProps
  } = props;

  const {colors: themeColors} = useTheme();

  const {titleColor, borderColor, backgroundColor, hoverColor} = useMemo(() => {
    switch (type) {
      case 'primary':
        return {
          titleColor: themeColors.card,
          backgroundColor: themeColors.primary,
          borderColor: themeColors.primary,
          hoverColor: colors.primaryShade,
        };
      case 'secondary':
        return {
          titleColor: themeColors.primary,
          backgroundColor: 'transparent',
          borderColor: themeColors.primary,
          hoverColor: themeColors.background,
        };
    }
  }, [type, themeColors]);

  return (
    <Pressable
      disabled={loading || disabled}
      style={state => [
        styles.button,
        typeof style === 'function' ? style(state) : style,
        {backgroundColor: color || backgroundColor, borderColor},
      ]}
      hoverColor={_hoverColor || hoverColor}
      {...buttonProps}>
      {title ? (
        <Text color={titleColor} style={titleStyle}>
          {title}
        </Text>
      ) : (
        children
      )}
      {(loading || disabled) && (
        <Flex style={[styles.loadingContainer]}>
          {loading && (
            <Flex alignItems={'center'} justifyContent={'center'} style={styles.loading}>
              <ActivityIndicator color={titleColor} />
            </Flex>
          )}
        </Flex>
      )}
    </Pressable>
  );
}
