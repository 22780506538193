import {initializeApp} from 'firebase/app';
import {connectAuthEmulator, getAuth} from 'firebase/auth';
import {connectFunctionsEmulator, getFunctions} from 'firebase/functions';
import {getAnalytics} from 'firebase/analytics';
import {getFirestore} from '@firebase/firestore';
import {connectFirestoreEmulator} from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyBxYBX-NEbTfzRKZF_6RBG4vxbk91wRrIc',
  authDomain: 'dateadvisor-8f3c3.firebaseapp.com',
  projectId: 'dateadvisor-8f3c3',
  storageBucket: 'dateadvisor-8f3c3.appspot.com',
  messagingSenderId: '1023258020825',
  appId: '1:1023258020825:web:a2709b8b6ad9863dbbe7c8',
  measurementId: 'G-DK7R1JD451',
};

const app = initializeApp(firebaseConfig);

export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app);
getAnalytics(app);

const useEmulator = false;
if (__DEV__ && useEmulator) {
  console.warn('using emulator');
  connectFunctionsEmulator(functions, '127.0.0.1', 5001);
  connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
  connectAuthEmulator(auth, 'http://127.0.0.1:9099', {disableWarnings: true});
}
