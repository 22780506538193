import React, {forwardRef, MutableRefObject, ReactElement, useRef, useState} from 'react';
import {StyleProp, StyleSheet, TextInput, TextInputProps, ViewStyle} from 'react-native';
import colors from '../constants/colors';
import Flex from './Flex';
import Text from './text/Text';
import {useTheme} from '@react-navigation/native';
import {Pressable} from './button';

const styles = StyleSheet.create({
  inputContainer: {
    borderRadius: 12,
    flexDirection: 'row',
    height: 36,
    paddingHorizontal: 6,
  },
  focusedInputContainer: {
    borderWidth: 1,
    borderColor: colors.primary,
  },
  errorInputContainer: {
    borderWidth: 1,
    borderColor: colors.red,
  },
  input: {
    paddingHorizontal: 13,
    flex: 1,
    fontSize: 18,
  },
  colorRed: {color: colors.red},
  // @ts-ignore
  defaultWebBorder0: {outlineWidth: 0},
});

export type InputProps = TextInputProps & {
  inputContainerStyle?: StyleProp<ViewStyle>;
  containerStyle?: StyleProp<ViewStyle>;
  InputLeftElement?: ReactElement;
  InputRightElement?: ReactElement;
  error?: string;
  placeholderTextColor?: string;
};

const Input = forwardRef<TextInput, InputProps>((props, ref) => {
  const {
    containerStyle,
    inputContainerStyle,
    style,
    InputLeftElement,
    InputRightElement,
    placeholderTextColor = colors.gray,
    onFocus,
    onBlur,
    value,
    error,
    ...inputProps
  } = props;

  const {colors: themeColors} = useTheme();

  const inputRef = useRef<TextInput | null>(null);

  const [isFocused, setIsFocused] = useState(false);

  return (
    <Flex gap={6} style={containerStyle}>
      <Pressable
        style={[
          styles.inputContainer,
          {backgroundColor: themeColors.background},
          isFocused && styles.focusedInputContainer,
          Boolean(error) && styles.errorInputContainer,
          inputContainerStyle,
        ]}
        onPress={() => {
          if (inputRef.current) {
            inputRef.current.focus();
          }
        }}>
        {InputLeftElement || null}
        <TextInput
          placeholderTextColor={placeholderTextColor}
          value={value}
          style={[styles.input, {color: themeColors.text}, styles.defaultWebBorder0, style]}
          ref={e => {
            if (ref) {
              (ref as MutableRefObject<TextInput | null>).current = e;
            }
            // @ts-ignore
            inputRef.current = e;
          }}
          onFocus={e => {
            setIsFocused(true);
            if (onFocus) {
              onFocus(e);
            }
          }}
          onBlur={e => {
            setIsFocused(false);
            if (onBlur) {
              onBlur(e);
            }
          }}
          {...inputProps}
        />
        {InputRightElement || null}
      </Pressable>
      {Boolean(error) && <Text style={styles.colorRed}>{error}</Text>}
    </Flex>
  );
});

export default Input;
