import React, { useCallback, useEffect, useRef, useState } from "react";
import { GiftedChat } from "react-native-gifted-chat";
import { addMessage, listenMessages } from "../../services/messagesService";
import { HomeStackParamList, IMessage } from "../../types";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import MaterialIcons from "@expo/vector-icons/MaterialIcons";
import { Flex, IconButton, Sizes, Text, Weights } from "../../components";
import { useTheme } from "@react-navigation/native";
import { StyleSheet } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import { useAppSelector } from "../../redux/store";
import { auth } from "../../services/firebase";
import Bubble from "./components/Bubble";
import { Reply } from "react-native-gifted-chat/lib/Models";
import { v4 as uuidv4 } from "uuid";
import { EmptyChatComponent } from "./EmptyChatComponent";
import { initialMessages } from "./InitialMessages";

const styles = StyleSheet.create({
  flex1: {flex: 1},
  header: {
    borderBottomWidth: 1,
    paddingHorizontal: 12,
    paddingVertical: 8,
  },
  horizontalMargin12: {marginHorizontal: 12},
});

type Props = NativeStackScreenProps<HomeStackParamList, 'ChatScreen'>;

export default function ChatScreen(props: Props) {
  const {navigation} = props;
  const {colors: themeColors} = useTheme();
  const user = useAppSelector(state => state.auth.user);
  const threadId = user.threadId;
  const [messages, setMessages] = useState<IMessage[]>(initialMessages);
  const [isLoading, setIsLoading] = useState(false);
  const [displayedMessage, setDisplayedMessage] = useState<string>('');
  const isFeedbackAsked = useRef(true);
  const typingInterval = useRef<NodeJS.Timeout | null>(null);
  const hasMounted = useRef(false);

  useEffect(() => {
    const userMessages = messages.filter(m => m.user._id !== 'assistant');
    if (userMessages.length >= 10 && user.isAnonymous) {
      navigation.navigate('SignUpModalScreen', {forced: true});
    } else if (
      userMessages.length % 10 === 0 &&
      userMessages.length > 0 &&
      !isFeedbackAsked.current
    ) {
      isFeedbackAsked.current = true;
      navigation.navigate('FeedbackModalScreen');
    }
  }, [messages, navigation, user]);

  useEffect(() => {
    return listenMessages(user.threadId, (newMessages: IMessage[]) =>
      setMessages(previousMessages => {
        if (
          hasMounted.current &&
          newMessages[0]?.user._id === 'assistant' &&
          newMessages[0]?.text
        ) {
          setDisplayedMessage('');
          const latestMessage = newMessages[0]?.text;
          const words = latestMessage.split(' ');
          const lastWord = words.pop() || '';
          const initialMessage = words.join(' ');

          if (typingInterval.current) {
            clearInterval(typingInterval.current);
          }

          let index = 0;
          typingInterval.current = setInterval(() => {
            setDisplayedMessage(`${initialMessage} ${lastWord.slice(0, index + 1)}`);
            index++;
            if (index >= lastWord.length) {
              clearInterval(typingInterval.current!);
            }
          }, 10); // Adjust the interval as needed for typing speed
        } else {
          setDisplayedMessage(newMessages[0]?.text || '');
          hasMounted.current = true;
        }

        return newMessages.length > 0 ? newMessages : previousMessages;
      }),
    );
  }, [user]);

  // console.log(messages);
  //
  const onSend = useCallback(
    async (_messages: IMessage[] = []) => {
      isFeedbackAsked.current = false;
      if (threadId) {
        setMessages(previousMessages => GiftedChat.append(previousMessages, _messages));
        _messages.forEach(m => {
          try {
            // setIsLoading(true);
            addMessage({...m, threadId});
          } catch (e) {
            setIsLoading(false);
            console.error(e);
            alert((e as Error).message);
          }
        });
      }
    },
    [threadId],
  );

  const onQuickReply = (replies: Reply[]) => {
    if (replies.length === 1) {
      const message = {
        _id: uuidv4(),
        text: replies[0].title,
        createdAt: new Date(),
        updatedAt: new Date(),
        user: {
          _id: user._id,
          name: 'User',
        },
      };
      onSend([message]);
    }
  };

  return (
    <SafeAreaView edges={['bottom']} style={styles.flex1}>
      <SafeAreaView
        edges={['top']}
        style={[
          styles.header,
          {backgroundColor: themeColors.card, borderBottomColor: themeColors.border},
        ]}>
        <Flex direction={'row'} justifyContent={'space-between'}>
          <IconButton
            onPress={() => navigation.navigate('FeedbackModalScreen')}
            style={styles.horizontalMargin12}>
            <MaterialIcons name={'feedback'} size={30} color={themeColors.primary} />
          </IconButton>

          {user.isAnonymous ? (
            <IconButton
              onPress={() => navigation.navigate('SignUpModalScreen', {forced: false})}
              style={styles.horizontalMargin12}>
              <MaterialIcons name={'person'} size={30} color={themeColors.primary} />
            </IconButton>
          ) : (
            <IconButton
              onPress={() => {
                if (confirm('Do you want to log out?')) {
                  auth.signOut().catch(e => {
                    console.error(e);
                    alert((e as Error).message);
                  });
                }
              }}
              style={styles.horizontalMargin12}>
              <MaterialIcons name={'logout'} size={30} color={themeColors.text} />
            </IconButton>
          )}
          <Flex
            pointerEvents={'none'}
            style={StyleSheet.absoluteFill}
            alignItems={'center'}
            justifyContent={'center'}>
            <Text size={Sizes.Title2} weight={Weights.Bold}>
              Ask Don Juan
            </Text>
            <Text size={Sizes.Caption2}>1.0.0</Text>
          </Flex>
        </Flex>
      </SafeAreaView>
      <GiftedChat
        renderBubble={p => <Bubble {...p} />}
        messages={messages.map(message => ({
          ...message,
          text:
            message._id === messages[0]._id && message.user._id === 'assistant'
              ? displayedMessage
              : message.text,
        }))}
        onSend={_messages => onSend(_messages as any)}
        user={{
          _id: user._id,
        }}
        disableComposer={isLoading}
        renderChatEmpty={() => <EmptyChatComponent />}
        onQuickReply={onQuickReply}
      />
    </SafeAreaView>
  );
}
