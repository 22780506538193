import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ReduxUser} from '../../types/user';

type AuthState = {
  user: ReduxUser;
};

const authInitialState: AuthState = {
  // @ts-ignore
  user: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    setUserAction(state, action: PayloadAction<ReduxUser>) {
      state.user = action.payload;
    },
    updateUserAction(state, action: PayloadAction<Partial<ReduxUser>>) {
      state.user = {...state.user, ...action.payload};
    },
    logOutAction() {
      return authInitialState;
    },
  },
});

export const {setUserAction, updateUserAction, logOutAction} = authSlice.actions;
const authReducer = authSlice.reducer;
export default authReducer;
