import React, {useState} from 'react';
import {PressableProps, Pressable as RNPressable, StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  opacity60p: {opacity: 0.6},
});

type Props = PressableProps & {
  hoverColor?: string;
};

export default function Pressable(props: Props) {
  const {hoverColor, style, onHoverIn, onHoverOut, ...buttonProps} = props;

  const [isHovered, setIsHovered] = useState(false);

  return (
    <RNPressable
      onHoverIn={e => {
        setIsHovered(true);
        if (onHoverIn) {
          onHoverIn(e);
        }
      }}
      onHoverOut={e => {
        setIsHovered(false);
        if (onHoverOut) {
          onHoverOut(e);
        }
      }}
      style={state => [
        typeof style === 'function' ? style(state) : style,
        isHovered && {backgroundColor: hoverColor},
        state.pressed && styles.opacity60p,
      ]}
      {...buttonProps}
    />
  );
}
