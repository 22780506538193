import React, {Dispatch, SetStateAction, useCallback, useState} from 'react';
import {Button, Flex, Input} from '../../../components';
import {regexes} from '../../../constants';
import {EmailAuthProvider, linkWithCredential} from 'firebase/auth';
import {auth} from '../../../services/firebase';
import {useAppDispatch} from '../../../redux/store';
import {updateUserAction} from '../../../redux/slices/authSlice';

type Field = 'email' | 'password' | 'confirmPassword';

type Props = {
  onSuccess: () => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
};

export default function SignUpWithEmailForm(props: Props) {
  const {onSuccess, setIsLoading, isLoading} = props;

  const dispatch = useAppDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState<Record<Field, string>>({
    email: '',
    password: '',
    confirmPassword: '',
  });

  const validatePassword = useCallback((value: string, field: Field) => {
    if (!value.match(regexes.noWhiteSpace)) {
      const errorMessage = 'Password can not contain whitespace';
      setErrors(prevState => ({...prevState, [field]: errorMessage}));
      return errorMessage;
    }
    return null;
  }, []);

  const handleChange = useCallback((setText: Dispatch<SetStateAction<string>>, field: Field) => {
    return (text: string) => {
      setText(text);
      setErrors(prevState => ({...prevState, [field]: ''}));
    };
  }, []);

  async function signUpWithEmailPassword() {
    try {
      if (!email.match(regexes.email)) {
        const errorMessage = 'Invalid email format';
        setErrors(prevState => ({...prevState, email: errorMessage}));
        throw new Error(errorMessage);
      }
      const passwordError = validatePassword(password, 'password');
      if (passwordError) {
        throw new Error(passwordError);
      }
      const confirmPasswordError = validatePassword(confirmPassword, 'confirmPassword');
      if (confirmPasswordError) {
        throw new Error(confirmPasswordError);
      }
      if (password !== confirmPassword) {
        const errorMessage = 'Passwords are not identical';
        setErrors(prevState => ({...prevState, confirmPassword: errorMessage}));
        throw new Error(errorMessage);
      }
      setIsLoading(true);
      const credential = EmailAuthProvider.credential(email, password);
      await linkWithCredential(auth.currentUser!, credential);
      await auth.currentUser!.reload();
      dispatch(updateUserAction({isAnonymous: auth.currentUser!.isAnonymous}));
      onSuccess();
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      alert((e as Error).message);
    }
  }

  return (
    <Flex gap={24}>
      <Flex gap={10}>
        <Input
          editable={!isLoading}
          placeholder={'Email'}
          value={email}
          onChangeText={handleChange(setEmail, 'email')}
          error={errors.email}
        />
        <Input
          editable={!isLoading}
          placeholder={'Password'}
          value={password}
          onChangeText={handleChange(setPassword, 'password')}
          error={errors.password}
        />
        <Input
          editable={!isLoading}
          placeholder={'Confirm Password'}
          value={confirmPassword}
          onChangeText={handleChange(setConfirmPassword, 'confirmPassword')}
          error={errors.confirmPassword}
        />
      </Flex>
      <Flex alignItems={'center'}>
        <Button loading={isLoading} title={'Sign up'} onPress={signUpWithEmailPassword} />
      </Flex>
    </Flex>
  );
}
