import {TextStyle} from 'react-native';

type Size = Pick<TextStyle, 'fontSize' | 'lineHeight'>;

export enum Sizes {
  LargeTitle = 'LargeTitle',
  Title1 = 'Title1',
  Title2 = 'Title2',
  Title3 = 'Title3',
  Headline = 'Headline',
  Callout = 'Callout',
  Subhead = 'Subhead',
  Footnote = 'Footnote',
  Caption1 = 'Caption1',
  Caption2 = 'Caption2',
}

export enum Weights {
  Thin = '100',
  ExtraLight = '200',
  Light = '300',
  Regular = '400',
  Medium = '500',
  SemiBold = '600',
  Bold = '700',
  ExtraBold = '800',
  Black = '900',
}

export const textSizes: Record<Sizes, Size> = {
  [Sizes.LargeTitle]: {
    fontSize: 34,
    lineHeight: 41,
  },
  [Sizes.Title1]: {
    fontSize: 28,
    lineHeight: 34,
  },
  [Sizes.Title2]: {
    fontSize: 22,
    lineHeight: 28,
  },
  [Sizes.Title3]: {
    fontSize: 20,
    lineHeight: 25,
  },
  [Sizes.Headline]: {
    fontSize: 17,
    lineHeight: 22,
  },
  [Sizes.Callout]: {
    fontSize: 16,
    lineHeight: 21,
  },
  [Sizes.Subhead]: {
    fontSize: 15,
    lineHeight: 20,
  },
  [Sizes.Footnote]: {
    fontSize: 13,
    lineHeight: 18,
  },
  [Sizes.Caption1]: {
    fontSize: 12,
    lineHeight: 16,
  },
  [Sizes.Caption2]: {
    fontSize: 11,
    lineHeight: 13,
  },
};
