import React, {forwardRef} from 'react';
import {Text as RNText, TextProps as ITextProps, TextStyle} from 'react-native';
import {Sizes, textSizes, Weights} from './types';
import {useTheme} from '@react-navigation/native';

export type TextProps = ITextProps & {
  color?: string;
  weight?: Weights;
  size?: Sizes;
  nested?: boolean;
  align?: TextStyle['textAlign'];
};

const Text = forwardRef<RNText, TextProps>((props, ref) => {
  const {nested = false, size, weight, color, align, style, ...textProps} = props;

  const {colors} = useTheme();

  if (nested) {
    return (
      <RNText
        ref={ref}
        style={[
          {
            ...(size ? textSizes[size] : {}),
            fontWeight: weight,
            color,
            textAlign: align,
          },
          style,
        ]}
        {...textProps}
      />
    );
  }

  return (
    <RNText
      ref={ref}
      style={[
        {
          ...textSizes[size || Sizes.Callout],
          fontWeight: weight,
          color: color || colors.text,
          textAlign: align,
        },
        style,
      ]}
      {...textProps}
    />
  );
});

export default Text;
