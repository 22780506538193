import React, {Dispatch, SetStateAction, useCallback, useState} from 'react';
import {Button, Flex, Input} from '../../../components';
import {regexes} from '../../../constants';
import {signInWithEmailAndPassword} from 'firebase/auth';
import {auth} from '../../../services/firebase';

type Field = 'email' | 'password';

type Props = {
  onSuccess: () => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
};

export default function SignInWithEmailForm(props: Props) {
  const {onSuccess, setIsLoading, isLoading} = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<Record<Field, string>>({
    email: '',
    password: '',
  });

  const handleChange = useCallback((setText: Dispatch<SetStateAction<string>>, field: Field) => {
    return (text: string) => {
      setText(text);
      setErrors(prevState => ({...prevState, [field]: ''}));
    };
  }, []);

  async function signInWithEmailPassword() {
    try {
      if (!email.match(regexes.email)) {
        const errorMessage = 'Invalid email format';
        setErrors(prevState => ({...prevState, email: errorMessage}));
        throw new Error(errorMessage);
      }
      setIsLoading(true);
      await signInWithEmailAndPassword(auth, email, password);
      onSuccess();
    } catch (e) {
      setIsLoading(false);
      console.error(e);
      alert((e as Error).message);
    }
  }

  return (
    <Flex gap={24}>
      <Flex gap={10}>
        <Input
          editable={!isLoading}
          placeholder={'Email'}
          value={email}
          onChangeText={handleChange(setEmail, 'email')}
          error={errors.email}
        />
        <Input
          editable={!isLoading}
          placeholder={'Password'}
          value={password}
          onChangeText={handleChange(setPassword, 'password')}
          error={errors.password}
        />
      </Flex>
      <Flex alignItems={'center'}>
        <Button loading={isLoading} title={'Log in'} onPress={signInWithEmailPassword} />
      </Flex>
    </Flex>
  );
}
