export default {
  white: '#FFFFFF',
  black: '#000000',
  gray: '#637e7e',
  text: '#0c383a',
  primary: '#20B2AA',
  primaryShade: '#147773',
  border: '#B8E3DF',
  card: '#FFFFFF',
  background: '#f2fffd',
  red: '#de5151',
};
