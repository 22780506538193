import React from 'react';
import {ActivityIndicator, StyleSheet} from 'react-native';
import Flex from './Flex';
import {useTheme} from '@react-navigation/native';

const styles = StyleSheet.create({
  flex1: {flex: 1},
});

export default function Spinner() {
  const {colors} = useTheme();

  return (
    <Flex style={styles.flex1} justifyContent={'center'} alignItems={'center'}>
      <ActivityIndicator size={'large'} color={colors.primary} />
    </Flex>
  );
}
