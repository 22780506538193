import {DefaultTheme, Theme} from '@react-navigation/native';
import colors from './colors';

export const LightTheme: Theme = {
  ...DefaultTheme,
  colors: {
    ...DefaultTheme.colors,
    card: colors.card,
    background: colors.background,
    primary: colors.primary,
    text: colors.text,
    border: colors.border,
  },
  dark: false,
};
