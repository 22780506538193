import React, {useState} from 'react';
import {StyleSheet, TextInput} from 'react-native';
import {Button, Flex, Text} from '../../../components';
import {trimText} from '../../../utils';
import {createFeedback} from '../../../services/feedbackService';
import {auth} from '../../../services/firebase';

const styles = StyleSheet.create({
  comment: {
    marginTop: 20,
    height: 70,
    borderWidth: 1,
    borderColor: '#555555',
    borderRadius: 5,
    padding: 3,
  },
  successText: {
    textAlign: 'center',
    fontWeight: 'bold',
    color: 'green',
  },
});

export default function FeedbackForm() {
  const user = auth.currentUser;

  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  async function onSubmit() {
    if (user) {
      try {
        const _comment = trimText(comment);
        if (!_comment) {
          setComment('');
          throw new Error('Comment is required field and can not contain only whitespaces');
        }
        setIsSubmitting(true);
        await createFeedback({
          comment: _comment,
          uid: user.uid,
        });
        setIsSubmitted(true);
        setComment('');
      } catch (e) {
        console.error(e);
        alert((e as Error).message);
      } finally {
        setIsSubmitting(false);
      }
    }
  }

  return (
    <Flex gap={16}>
      <TextInput
        style={styles.comment}
        value={comment}
        onChangeText={setComment}
        placeholder="Add your comments..."
        multiline
        numberOfLines={4}
      />
      <Flex alignItems={'center'}>
        <Button loading={isSubmitting} title={'SUBMIT'} disabled={!comment} onPress={onSubmit} />

        {isSubmitted && <Text style={styles.successText}>Form submitted ✅</Text>}
      </Flex>
    </Flex>
  );
}
